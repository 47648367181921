import { Component } from '@angular/core'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'

@Component({
  selector: 'app-energy-management',
  templateUrl: './energy-management.component.html',
  styleUrls: ['./energy-management.component.scss'],
})
export class EnergyManagementComponent {
  constructor(
    public ui: UiService,
    public proficloud: ProficloudService
  ) {}
}
