import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { format } from 'date-fns'
import { BehaviorSubject, Subject } from 'rxjs'
import {
  AcceptableMeasurementTypes,
  AggregatedMetric,
  BaseUnit,
  BaseUnitID,
  BaseUnitSymbol,
  BaseUnitTypeID,
  BaseUnitWithInfo,
  Calculation,
  CompositeUnitType,
  DMSDeviceMapping,
  DataSource,
  DropDownItem,
  EMMASimulation,
  EmmaAlertMessage,
  FrequencySelection,
  MeteringPoint,
  Metric,
  OrganisationRole,
  PackageType,
  QueryableDataSourceTypes,
  QueryableNodeType,
  RecurringFrequencies,
  RecurringReport,
  SelectConfig,
  SelectGroup,
  SelectGroupedItems,
  SelectSubItem,
  SelectValue,
  TreeNodeTypes,
  Unit,
  UnitPrefix,
  UnitPrefixSymbol,
  UnitTypeDefaults,
  UnitTypeKind,
  WageTypeInfo,
  WagesTypes,
  WeekdayIDs,
  WeekdayNames,
  Widget,
  WindowPeriods,
} from '../../../services/proficloud.interfaces'
import { ProficloudService } from '../../../services/proficloud.service'
import { Member } from '../../user-management/entities/member.entity'
import { MemberStore } from '../../user-management/stores/member.store'
import { EmmaStore } from '../stores/emma.store'

@Injectable({
  providedIn: 'root',
})
export class EmmaService {
  public DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss"

  public baseUnitTypeDefaults: Map<BaseUnitTypeID, UnitTypeDefaults> = new Map()
  public wageTypeInfo: Map<Exclude<AcceptableMeasurementTypes, null>, WageTypeInfo> = new Map()
  public baseTypes: BaseUnitTypeID[] = []
  public compositeTypes: CompositeUnitType[] = []
  public baseUnits: BaseUnitWithInfo[] = []

  public usedPrefixes: UnitPrefix[] = ['MICRO', 'MILLI', 'CENTI', 'DECI', '_', 'DECA', 'HECTO', 'KILO', 'MEGA', 'GIGA']

  public priorityMap = {
    3: { value: 'low', label: 'Low Priority' },
    2: { value: 'medium', label: 'Medium Priority' },
    1: { value: 'high', label: 'High Priority' },
  }

  public windowPeriodLabels: Record<WindowPeriods, string> = {
    '1s': 'Secondly',
    '1min': 'Minutely',
    '15min': '15 Minutely',
    '1h': 'Hourly',
    '1d': 'Daily',
    '1w': 'Weekly',
    '1m': 'Monthly',
    '1y': 'Yearly',
  }

  public timeUnitLabels: Record<WindowPeriods, string> = {
    '1s': 'Second',
    '1min': 'Minute',
    '15min': 'Minute',
    '1h': 'Hour',
    '1d': 'Day',
    '1w': 'Week',
    '1m': 'Month',
    '1y': 'Year',
  }

  public wagesBaseTypes: Record<WagesTypes, BaseUnitTypeID> = {
    'coldthermal-energy': 'ENERGY',
    'compressed-air': 'VOLUME',
    'electrical-energy': 'ENERGY',
    'gas-energy': 'ENERGY',
    'gas-volume': 'VOLUME',
    'hotthermal-energy': 'ENERGY',
    'steamthermal-energy': 'ENERGY',
    'water-volume': 'VOLUME',
  }

  public measurementTypesBaseTypes: Record<Exclude<AcceptableMeasurementTypes, null>, BaseUnitTypeID> = {
    'electrical-energy': 'ENERGY',
    'electrical-power': 'POWER',
    'water-volume': 'VOLUME',
    'gas-volume': 'VOLUME',
    'gas-energy': 'ENERGY',
    'coldthermal-energy': 'ENERGY',
    'hotthermal-energy': 'ENERGY',
    'steamthermal-energy': 'ENERGY',
    'compressed-air': 'VOLUME',
    pressure: 'PRESSURE',
  }

  public measurementTypeShortLabels: Record<Exclude<AcceptableMeasurementTypes, null>, string> = {
    'electrical-power': 'E Power',
    'electrical-energy': 'E Energy',
    'water-volume': 'W Volume',
    'gas-volume': 'G Volume',
    'gas-energy': 'G Energy',
    'coldthermal-energy': 'CT Energy',
    'hotthermal-energy': 'HT Energy',
    'steamthermal-energy': 'ST Energy',
    'compressed-air': 'C Air Volume',
    pressure: 'Pressure',
  }

  public dayNames: Record<WeekdayIDs, WeekdayNames> = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday',
  }

  public acceptableDeviceTypes = ['EEM-SB370-C', 'EEM-SB371-C', 'virtual']

  public allBaseUnits: BaseUnit[] = []

  public composedBaseUnits: string[] = []

  public dashboardSwitchStarted$ = new Subject()

  public globalColourMapUpdateRequired$ = new Subject()

  public globalColourMapUpdated$ = new Subject<boolean>() // params = is initial colour map construction

  public dataSourceColourChanged$ = new Subject<string>()

  public showDeleteRuleConfirmation$ = new Subject<string | false>()

  public scrollToTreeNode$ = new Subject<string>()

  public scrollToTreeNodeRequired$ = new Subject<string>()

  public showColourPicker$ = new Subject<
    | {
        widget: Widget
        index: number
        queryable?: QueryableDataSourceTypes
      }
    | false
  >()

  public permissionsSet$ = new Subject<boolean>()

  public showAddMetrics$ = new Subject<boolean | 'inbox' | 'calculation'>()
  public showNewWidget$ = new Subject<boolean>()
  public showEditCalulcation$ = new Subject<Calculation | false>()
  public showAddChartDatasource$ = new Subject<{ type: BaseUnitTypeID; usedIds: string[]; onSelect: (ids: string[]) => void } | false>()

  public showDeleteCalculationConfirmation$ = new Subject<Calculation | false>()
  public highlightCalculation$ = new Subject<string>()

  public showEditMetricDialog$ = new BehaviorSubject<{ id: string; name: string } | false>(false)
  public showEditAggregatedMetricDialog$ = new BehaviorSubject<AggregatedMetric | false>(false)
  public showExportDialog$ = new BehaviorSubject<boolean>(false)

  public fullScreenWidget$ = new Subject<[Partial<Widget>, number | null] | false>()

  public clearTreeHighlight$ = new Subject()

  public showAlertRuleMessages$ = new Subject<string | false>()

  public searchInput$ = new BehaviorSubject<string>('')

  public alertExpansion$ = new Subject<EmmaAlertMessage>()

  public acknowledgeMessage$ = new Subject<string>()

  public canEdit: boolean

  public isAdmin: boolean

  public isPhoenixUser: boolean

  public emmaSimulationEdit$ = new Subject<EMMASimulation | false>()

  public showPurchaseUpgradeMessage$ = new Subject<{ title: string; message: string } | false>()

  public showExportWidgets$ = new Subject<boolean>()

  public showReports$ = new Subject<{ recurring: boolean; toEdit?: RecurringReport } | false>()

  public showFrequencyPicker$ = new BehaviorSubject<
    | {
        onSelect: (selected: FrequencySelection) => void
        current?: FrequencySelection
      }
    | false
  >(false)

  public UNIT_PREFIXES: Record<UnitPrefix, { name: string; symbol: UnitPrefixSymbol }> = {
    QUECTO: { name: 'Quecto', symbol: 'q' },
    RONTO: { name: 'Ronto', symbol: 'r' },
    YOCTO: { name: 'Yocto', symbol: 'y' },
    ZEPTO: { name: 'Zepto', symbol: 'z' },
    ATTO: { name: 'Atto', symbol: 'a' },
    FEMTO: { name: 'Femto', symbol: 'f' },
    PICO: { name: 'Pico', symbol: 'p' },
    NANO: { name: 'Nano', symbol: 'n' },
    MICRO: { name: 'Micro', symbol: 'µ' },
    MILLI: { name: 'Milli', symbol: 'm' },
    CENTI: { name: 'Centi', symbol: 'c' },
    DECI: { name: 'Deci', symbol: 'd' },
    _: { name: '', symbol: '' },
    DECA: { name: 'Deca', symbol: 'da' },
    HECTO: { name: 'Hecto', symbol: 'h' },
    KILO: { name: 'Kilo', symbol: 'k' },
    MEGA: { name: 'Mega', symbol: 'M' },
    GIGA: { name: 'Giga', symbol: 'G' },
    TERA: { name: 'Tera', symbol: 'T' },
    PETA: { name: 'Peta', symbol: 'P' },
    EXA: { name: 'Exa', symbol: 'E' },
    ZETTA: { name: 'Zetta', symbol: 'Z' },
    YOTTA: { name: 'Yotta', symbol: 'Y' },
    RONNA: { name: 'Ronna', symbol: 'R' },
    QUETTA: { name: 'Quetta', symbol: 'Q' },
  }

  public BASE_UNIT_SYMBOLS: Record<BaseUnitID, BaseUnitSymbol> = {
    WATT: 'W',
    HORSEPOWER: 'hp',
    JOULE: 'J',
    WATT_HOUR: 'Wh',
    WATT_MINUTE: 'Wm',
    WATT_SECOND: 'Ws',
    CALORIE: 'cal',
    HORSEPOWER_HOUR: 'hph',
    CELSIUS: '°C',
    FAHRENHEIT: '°F',
    KELVIN: 'K',
    HUM_RELATIVE: '',
    HUM_PERCENT: '%',
    CUBIC_METER: 'm³',
    LITER: 'l',
    GALLON: 'gal',
    CUBIC_FEET: 'ft³',
    PASCAL: 'Pa',
    POUND_PER_SQUARE_INCH: 'PSI',
    BAR: 'bar',
    ATMOSPHERE: 'atm',
    NEWTON_PER_SQUARE_METER: 'N/m²',
    SQUARE_METER: 'm²',
    GRAM: 'g',
    TONNE: 't',
  }

  public BASE_UNIT_LABELS: Record<BaseUnitID, string> = {
    WATT: 'Watt',
    HORSEPOWER: 'Horsepower',
    JOULE: 'Joule',
    WATT_HOUR: 'Watt-hour',
    WATT_MINUTE: 'Watt-minute',
    WATT_SECOND: 'Watt-second',
    CALORIE: 'Calorie',
    HORSEPOWER_HOUR: 'Horsepower-hour',
    CELSIUS: '°Celsius',
    FAHRENHEIT: 'Fahrenheit',
    KELVIN: 'Kelvin',
    HUM_RELATIVE: 'Relative humidity',
    HUM_PERCENT: 'Humidity',
    CUBIC_METER: 'Cubic meter',
    LITER: 'Liter',
    GALLON: 'Gallon',
    CUBIC_FEET: 'Cubic feet',
    PASCAL: 'Pascal',
    POUND_PER_SQUARE_INCH: 'Pounds per square inch',
    BAR: 'Bar',
    ATMOSPHERE: 'Atmospheres',
    NEWTON_PER_SQUARE_METER: 'Newtons per square meter',
    SQUARE_METER: 'Square meter',
    GRAM: 'Gram',
    TONNE: 'Tonne',
    AMPERE: 'Ampere',
    VOLTAGE: 'Volt',
    HEATING_DEGREE_DAYS: 'Heating Degree Days',
    COOLING_DEGREE_DAYS: 'Cooling Degree Days',
  }

  unitTypeKind: UnitTypeKind = {
    ENERGY: 'CONSUMPTION',
    POWER: 'MEASUREMENT',
    PRESSURE: 'MEASUREMENT',
    TEMPERATURE: 'MEASUREMENT',
    HUMIDITY: 'MEASUREMENT',
    CURRENT: 'MEASUREMENT',
    VOLTAGE: 'MEASUREMENT',
    VOLUME: 'CONSUMPTION',
  }

  // Note: this is a flat list of tree nodes without a root element for convenience
  meterTree: MeteringPoint[]
  metricsInbox: Metric[] = []

  flatMeterMetrics: Metric[] = []
  flatAggregatedMetrics: AggregatedMetric[] = []
  flatMeteringPoints: MeteringPoint[] = []
  nodeNameLookup: Record<string, string> = {}

  DEFAULT_NODE_COLOUR = 'var(--primary)'
  DEFAULT_METRIC_COLOUR = 'hotpink'
  NODE_TYPES: TreeNodeTypes[] = ['site', 'building', 'machine', 'device', 'steam', 'water', 'air', 'heat', 'energy', 'energy', 'tree', 'default']

  // TODO: Move this into the subject call
  public activeTreeNodeId?: string

  formulae: Calculation[] = []
  sums: Calculation[] = []
  averages: Calculation[] = []
  allCalculations: Calculation[] = []

  deviceMappings: DMSDeviceMapping[]

  allQueryables: QueryableDataSourceTypes[] = []

  public sup: Record<number, string> = {
    0: '⁰',
    1: '¹',
    2: '²',
    3: '³',
    4: '⁴',
    5: '⁵',
    6: '⁶',
    7: '⁷',
    8: '⁸',
    9: '⁹',
  }

  public currentlyLiveWidgets = 0

  public packageTypeLabels: Record<PackageType, string> = {
    FULL: 'Professional',
    LEGACY: 'Legacy',
    STARTER: 'Trial',
    NONE: 'None',
  }

  public orgMembers: Member[] = []

  constructor(
    public proficloud: ProficloudService,
    public http: HttpClient,
    private emmaStore: EmmaStore,
    private memberStore: MemberStore
  ) {
    this.emmaStore.allTypesAndUnits$.subscribe({
      next: (res) => {
        // First down the pipe on init is a bunch of empty arrays/maps so we need to ignore this
        if (!res?.baseUnits.length) {
          return
        }
        this.baseUnitTypeDefaults = res.baseUnitTypeDefaults
        this.wageTypeInfo = res.wageTypeInfo
        this.baseTypes = res.baseTypes
        this.compositeTypes = res.compositeTypes
        this.baseUnits = res.baseUnits
        this.composeCompositeUnitTypes()
      },
      error: (err) => {
        // TODO: Handle error
      },
    })

    this.emmaStore.queryableDataSources$.subscribe({
      next: (res) => {
        // First one down the pipe is empty
        if (!res.tree) {
          return
        }
        this.meterTree = res.tree
        this.setSortedMetricsLists()

        this.metricsInbox = res.inbox
        this.metricsInbox.forEach((metric) => {
          this.setCombinedMetricUnit(metric)
        })
        this.allCalculations = res.sums.concat(res.avgs).concat(res.kpis)
        this.formulae = res.kpis
        this.sums = res.sums
        this.averages = res.avgs
        this.populateFlatLookupLists()
        this.deviceMappings = res.mappings
      },
    })

    this.memberStore.members$.subscribe((members) => {
      this.orgMembers = members
    })

    if (this.proficloud.organisations?.length) {
      this.setPermissions()
    } else {
      this.proficloud.organisationsListed$.subscribe(() => {
        this.setPermissions()
      })
    }

    this.proficloud.organisationSwitched$.subscribe(() => {
      this.setPermissions()
    })

    this.proficloud.userDataFetched$.subscribe(() => {
      this.setPermissions()
    })
  }

  private setSortedMetricsLists() {
    const setSorted = (meteringPoint: MeteringPoint) => {
      // Put all metrics and aggregated metrics into array together
      meteringPoint.allSortedMetrics = ([] as (Metric | AggregatedMetric)[]).concat(meteringPoint.aggregatedMetrics).concat(meteringPoint.metrics)

      // Sort them based on unit type then measurement type
      meteringPoint.allSortedMetrics.sort((a, b) => {
        let unitTypeA = ''
        let unitTypeB = ''
        let measurementTypeA = ''
        let measurementTypeB = ''

        if (this.isAggregatedMetric(a)) {
          unitTypeA = a.unitType
          measurementTypeA = a.measurementType || ''
        }

        if (this.isMetric(a)) {
          unitTypeA = a.unit.baseUnit.unitType
          measurementTypeA = a.measurementType || ''
        }

        if (this.isAggregatedMetric(b)) {
          unitTypeB = b.unitType
          measurementTypeB = b.measurementType || ''
        }

        if (this.isMetric(b)) {
          unitTypeB = b.unit.baseUnit.unitType
          measurementTypeB = b.measurementType || ''
        }

        if (unitTypeB === unitTypeA) {
          if (measurementTypeA && measurementTypeB) {
            return measurementTypeB > measurementTypeA ? -1 : 1
          }
          return 0
        }

        return unitTypeB > unitTypeA ? -1 : 1
      })

      // Recurse on children
      meteringPoint.children.forEach(setSorted)
    }

    this.meterTree.forEach((mp) => {
      setSorted(mp)
    })
  }

  private setPermissions() {
    this.canEdit = [OrganisationRole.ROLE_ADMIN, OrganisationRole.ROLE_EDITOR].includes(this.proficloud.currentOrganisation.userRole)
    this.isAdmin = [OrganisationRole.ROLE_ADMIN].includes(this.proficloud.currentOrganisation.userRole)
    this.isPhoenixUser = !!this.proficloud.userIsPhoenix()
    this.permissionsSet$.next(true)
  }

  private setTreeMetaData() {
    // Pre-calculate total metric count property on all nodes so we can avoid change detection leaks
    const sumAllMetrics = (node: MeteringPoint) => {
      let count: number = node.metrics.length
      let childCount: number = node.children.map((child) => sumAllMetrics(child)).reduce((prev, curr) => prev + curr, 0)

      node.totalMetricCount = count + childCount
      return node.totalMetricCount
    }
    this.meterTree.forEach((node) => {
      sumAllMetrics(node)
    })
  }

  public getNodeId(ds: DataSource) {
    if (!ds) {
      console.trace()
      return ''
    }
    return ds.nodes[0].type === 'metering_point' ? ds.nodes[0].value.id : ds.nodes[0].value
  }

  public populateFlatLookupLists() {
    const extractMetrics = (node: MeteringPoint) => {
      this.nodeNameLookup[node.id] = node.name
      this.flatMeteringPoints.push(node)

      // Regular metrics
      if (node.metrics) {
        this.flatMeterMetrics = this.flatMeterMetrics.concat(node.metrics)
        node.metrics.forEach((metric) => {
          this.nodeNameLookup[metric.id] = metric.name
          this.setCombinedMetricUnit(metric)
        })
      }

      // Aggregated metrics
      if (node.aggregatedMetrics) {
        this.flatAggregatedMetrics = this.flatAggregatedMetrics.concat(node.aggregatedMetrics)
        node.aggregatedMetrics.forEach((am) => {
          this.nodeNameLookup[am.id] = am.name
        })
      }

      // Iterate over children
      if (node.children) {
        node.children.forEach((child) => {
          extractMetrics(child)
        })
      }
    }

    this.flatMeterMetrics = []
    this.flatMeteringPoints = []
    this.flatAggregatedMetrics = []
    this.meterTree.forEach((node) => {
      extractMetrics(node)
    })

    this.allQueryables.length = 0
    this.allQueryables = this.allQueryables.concat(this.flatMeterMetrics)
    this.allQueryables = this.allQueryables.concat(this.flatMeteringPoints)
    this.allQueryables = this.allQueryables.concat(this.allCalculations)
    // Note: Not including aggregated metrics as we are not allowed to directly query for them anyway
    // this.allQueryables = this.allQueryables.concat(this.flatAggregatedMetrics)

    this.setTreeMetaData()
  }

  /** BEGIN template helpers */
  public periodLabel(alert: EmmaAlertMessage) {
    return alert.period
  }

  public isMetric(dataSource?: QueryableDataSourceTypes): dataSource is Metric {
    return !!dataSource && 'unit' in dataSource
  }

  public isAggregatedMetric(dataSource?: QueryableDataSourceTypes): dataSource is AggregatedMetric {
    return !!dataSource && 'unitType' in dataSource && 'measurementType' in dataSource
  }

  public isMeteringPoint(dataSource?: QueryableDataSourceTypes): dataSource is MeteringPoint {
    return !!dataSource && 'children' in dataSource
  }

  public isCalculation(dataSource?: QueryableDataSourceTypes): dataSource is Calculation {
    return !!dataSource && 'calculationType' in dataSource
  }

  private applyToAllChildMetrics(meteringPoint: MeteringPoint, test: (metric: Metric) => boolean): Metric[] {
    let truthyMetrics: Metric[] = []

    meteringPoint.metrics.forEach((metric) => {
      if (test(metric)) {
        truthyMetrics.push(metric)
      }
    })

    meteringPoint.children.forEach((child) => {
      truthyMetrics = truthyMetrics.concat(this.applyToAllChildMetrics(child, test))
    })

    return truthyMetrics
  }

  public setDataSourceDropdownExclusions(
    baseUnitType: BaseUnitTypeID | string | undefined,
    items: DropDownItem<QueryableDataSourceTypes>[],
    usedIDs?: string | string[]
  ) {
    const exclude = (item: DropDownItem<QueryableDataSourceTypes>) => {
      if (this.isMetric(item.item)) {
        item.hidden = item.item.unit.baseUnit.unitType !== baseUnitType
      }
      if (this.isCalculation(item.item)) {
        item.hidden = this.getCompositeUnitStringRepresentation(item.item.unitType) !== baseUnitType
      }
      if (this.isMeteringPoint(item.item)) {
        item.hidden = !this.applyToAllChildMetrics(item.item, (metric) => metric.unit.baseUnit.unitType === baseUnitType).length
      }

      // Exclude if already being used in the widget
      if (usedIDs) {
        if (Array.isArray(usedIDs)) {
          item.hidden ||= !!item.id && usedIDs.includes(item.id)
        } else {
          item.hidden ||= usedIDs === item.id
        }
      }
    }
    items.forEach((item) => {
      exclude(item)
    })
  }

  public setDataSourceSelectExclusions(
    baseUnitType: BaseUnitTypeID | string | null,
    config: SelectConfig<QueryableDataSourceTypes>,
    usedIDs?: string | string[]
  ) {
    const exclude = (subItem: SelectSubItem<QueryableDataSourceTypes>) => {
      if (this.isMetric(subItem.item)) {
        subItem.excluded = subItem.item.unit.baseUnit.unitType !== baseUnitType
      }
      if (this.isAggregatedMetric(subItem.item)) {
        subItem.excluded = subItem.item.unitType !== baseUnitType
      }
      if (this.isCalculation(subItem.item)) {
        subItem.excluded = this.getCompositeUnitStringRepresentation(subItem.item.unitType) !== baseUnitType
      }
      if (this.isMeteringPoint(subItem.item)) {
        subItem.excluded = !this.applyToAllChildMetrics(subItem.item, (metric) => metric.unit.baseUnit.unitType === baseUnitType).length
      }

      // Exclude if already being used in the widget
      if (usedIDs) {
        if (Array.isArray(usedIDs)) {
          subItem.excluded ||= usedIDs.includes(subItem.key)
        } else {
          subItem.excluded ||= usedIDs === subItem.key
        }
      }
    }
    if (Array.isArray(config.items)) {
      config.items.forEach((subItem) => {
        exclude(subItem)
      })
    } else {
      Object.keys(config.items)
        .map((k) => (config.items as SelectGroupedItems<QueryableDataSourceTypes>)[k])
        .forEach((group: SelectGroup<QueryableDataSourceTypes>) => {
          group?.values?.forEach((subItem) => {
            exclude(subItem)
          })
        })
      Object.keys(config.items).forEach((k) => {
        ;(config.items as SelectGroupedItems<QueryableDataSourceTypes>)[k].values = Array.from(
          (config.items as SelectGroupedItems<QueryableDataSourceTypes>)[k].values
        )
      })
    }
  }

  public getBaseUnitTypeDefaultOutputSymbol(id: BaseUnitTypeID, prefix?: UnitPrefix, kpiId?: string) {
    if (id === 'kpi') {
      const mapping: { [key in BaseUnitTypeID]?: Unit } = {}
      for (let [key, value] of this.baseUnitTypeDefaults) {
        mapping[key] = value.defaultOutputUnit
      }

      const f = this.formulae.find((f) => f.id === kpiId)
      return !!f && this.getCompositeUnitStringUnitsRepresentation(f.unitType, mapping)
    } else {
      const outputUnit = this.baseUnitTypeDefaults.get(id)?.defaultOutputUnit
      return !!outputUnit && this.getUnitSymbol(outputUnit, prefix)
    }
  }

  public getUnitSymbol(unit: Unit, forcePrefix?: UnitPrefix): string {
    let unitSymbol: string = unit.baseUnit.symbol || this.BASE_UNIT_SYMBOLS[unit.baseUnit.unit]
    if (this.isCustomUnitType(unit.baseUnit.unitType)) {
      unitSymbol = unit.baseUnit.unitType.substring(7)
    }
    return this.getUnitPrefixSymbol(forcePrefix || unit.prefix) + unitSymbol
  }

  public getBaseUnitSymbol(baseUnit: BaseUnit): string {
    return baseUnit.symbol
  }

  public getUnitPrefixSymbol(prefix: UnitPrefix): string {
    return this.UNIT_PREFIXES[prefix].symbol
  }

  public getBaseUnitTypeLabel(id: BaseUnitTypeID, kpiId?: string) {
    if (id === 'kpi' && kpiId) {
      const f = this.formulae.find((f) => f.id === kpiId)
      return !!f && this.getCompositeUnitStringLabelRepresentation(f.unitType)
    } else {
      return this.capitalise(id.toLowerCase())
    }
  }

  /** END template helpers */

  public isCustomUnitType(u: BaseUnitTypeID): boolean {
    return u.startsWith('CUSTOM:')
  }

  public unitTypeRepresentation(u: BaseUnitTypeID): string {
    return (this.isCustomUnitType(u) ? u.substring(7) : u).toLocaleLowerCase()
  }

  public setCombinedMetricUnit(metric: Metric) {
    metric.combinedUnit = this.getUnitSymbol(metric.unit)
  }

  public getMetricName(metricID: string) {
    return this.flatMeterMetrics.find((m) => m.id === metricID)?.name
  }

  public getMetric(metricID: string): Metric | undefined {
    return this.flatMeterMetrics.find((m) => m.id === metricID)
  }

  public getMeteringPointName(id: string) {
    return this.flatMeteringPoints.find((mp) => mp.id === id)?.name
  }

  public getDataSourceNodeType(dataSourceId: string): QueryableNodeType {
    const node = this.flatMeteringPoints.find((n) => n.id === dataSourceId)
    if (node) {
      return 'metering_point'
    }

    const metric = this.flatMeterMetrics.find((m) => m.id === dataSourceId) || this.metricsInbox.find((m) => m.id === dataSourceId)
    if (metric) {
      return 'metric'
    }

    const aggregatedMetric = this.flatAggregatedMetrics.find((m) => m.id === dataSourceId)
    if (aggregatedMetric) {
      return 'aggregated_metric'
    }

    const calculation = this.allCalculations.find((c) => c.id === dataSourceId)
    if (calculation) {
      return 'calculation'
    }

    console.warn('Queryable node not found, returning default metric type instead')
    return 'metric'
  }

  public getDataSourceName(dataSourceId: string) {
    const dataSource =
      this.flatMeteringPoints.find((mp) => mp.id === dataSourceId) ||
      this.flatMeterMetrics.find((m) => m.id === dataSourceId) ||
      this.flatAggregatedMetrics.find((m) => m.id === dataSourceId) ||
      this.metricsInbox.find((m) => m.id === dataSourceId) ||
      this.allCalculations.find((c) => c.id === dataSourceId)

    return dataSource?.name
  }

  public getMetricBaseUnit(dataSourceId: string) {
    const metric = this.flatMeterMetrics.find((m) => m.id === dataSourceId)
    if (metric) {
      return metric.unit.baseUnit.unitType
    }

    const aggMetric = this.flatAggregatedMetrics.find((m) => m.id === dataSourceId)
    if (aggMetric) {
      return aggMetric.unitType
    }
  }

  public getQueryable(id: string) {
    return (
      this.flatMeteringPoints.find((n) => n.id === id) ||
      this.flatMeterMetrics.find((m) => m.id === id) ||
      this.flatAggregatedMetrics.find((m) => m.id === id) ||
      this.allCalculations.find((c) => c.id === id) ||
      this.metricsInbox.find((c) => c.id === id)
    )
  }

  public getQueryableName(dataSourceId: string) {
    return this.getQueryable(dataSourceId)?.name
  }

  // Note: My gut feeling is this is brittle and could be improved
  public getCompositeUnitStringRepresentation(ct: CompositeUnitType) {
    let typeString = ''

    // If there are no dividends or divisors, it's unitless
    if (!ct.dividend.length && !ct.divisor.length) {
      return 'Unitless'
    }

    // If there are no dividends but there are divisors, we have to specify that the dividend is unitless
    if (!ct.dividend.length && ct.divisor.length) {
      typeString += 'Unitless'
    }

    // Otherwise construct as normal
    ct.dividend.forEach((d, i) => {
      let unitTypeName = d.unitType
      if (this.isCustomUnitType(unitTypeName)) unitTypeName = unitTypeName.substring(7)
      typeString += `${i > 0 ? '* ' : ''}${unitTypeName}${d.count > 1 ? this.sup[d.count] : ''}`
    })
    if (ct.divisor.length > 0) {
      typeString += '/'
      ct.divisor.forEach((d, i) => {
        let unitTypeName = d.unitType
        if (this.isCustomUnitType(unitTypeName)) unitTypeName = unitTypeName.substring(7)
        typeString += `${i > 0 ? '* ' : ''}${unitTypeName}${d.count > 1 ? this.sup[d.count] : ''}`
      })
    }
    return typeString
  }

  public getCompositeUnitStringUnitsRepresentation(ct: CompositeUnitType, unitTypeBaseUnits: { [key in BaseUnitTypeID]?: Unit }) {
    let typeString = ''
    ct.dividend.forEach((d, i) => {
      const baseUnit = unitTypeBaseUnits[d.unitType]
      if (baseUnit) {
        const unitSymbol = this.getUnitSymbol(baseUnit)
        typeString += `${i > 0 ? '* ' : ''}${unitSymbol}${d.count > 1 ? this.sup[d.count] : ''}`
      }
    })
    if (ct.divisor.length > 0) {
      typeString += '/'
      ct.divisor.forEach((d, i) => {
        const baseUnit = unitTypeBaseUnits[d.unitType]
        if (baseUnit) {
          const unitSymbol = this.getUnitSymbol(baseUnit)
          typeString += `${i > 0 ? '* ' : ''}${unitSymbol}${d.count > 1 ? this.sup[d.count] : ''}`
        }
      })
    }
    if (typeString == '') {
      typeString = 'Unitless'
    }
    return typeString
  }

  public getCompositeUnitStringLabelRepresentation(ct: CompositeUnitType) {
    let typeString = ''
    ct.dividend.forEach((d, i) => {
      let defaultUnitLabel = this.capitalise(d.unitType)
      if (this.isCustomUnitType(d.unitType)) {
        defaultUnitLabel = this.capitalise(d.unitType.substring(7))
      }
      typeString += `${i > 0 ? '* ' : ''}${defaultUnitLabel}${d.count > 1 ? this.sup[d.count] : ''}`
    })
    if (ct.divisor.length > 0) {
      typeString += '/'
      ct.divisor.forEach((d, i) => {
        const defaultUnitLabel = this.capitalise(d.unitType)
        typeString += `${i > 0 ? '* ' : ''}${defaultUnitLabel}${d.count > 1 ? this.sup[d.count] : ''}`
      })
    }
    return typeString
  }

  public capitalise(str: string) {
    return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
  }

  public composeCompositeUnitTypes() {
    this.composedBaseUnits.length = 0
    this.compositeTypes.forEach((ct) => {
      this.composedBaseUnits.push(this.getCompositeUnitStringRepresentation(ct))
    })
  }

  public interpretCompositeUnit(kpiId: string): string {
    const kpi = this.allCalculations.find((c) => c.id === kpiId)
    return kpi ? this.getCompositeUnitStringRepresentation(kpi.unitType) : 'unknown'
  }

  public compositeUnitInvolves(kpiId: string, unit: BaseUnitTypeID) {
    const kpi = this.allCalculations.find((c) => c.id === kpiId)
    if (kpi) {
      return (
        kpi.unitType.dividend
          .map((u) => {
            return u.unitType === unit
          })
          .reduce((a, b) => {
            return a || b
          }, false) ||
        kpi.unitType.divisor
          .map((u) => {
            return u.unitType === unit
          })
          .reduce((a, b) => {
            return a || b
          }, false)
      )
    } else {
      return false
    }
  }

  public baseUnitPrefixName(baseUnit: BaseUnitID, prefix: UnitPrefix) {
    const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)
    if (this.isCustomUnitType(baseUnit)) {
      return baseUnit.substring(7)
    }
    return prefix === '_' ? capitalize(baseUnit.replace('_', ' ').toLowerCase()) : this.UNIT_PREFIXES[prefix].name + baseUnit.replace('_', ' ').toLowerCase()
  }

  public wagesTypesToSelectValues(unitType: BaseUnitTypeID): { key: string; value: string; checked: false; visible: true }[] {
    return [...this.wageTypeInfo.keys()]
      .filter((mt) => {
        const wageInfo = this.wageTypeInfo.get(mt)
        return wageInfo && wageInfo.unitType === unitType
      })
      .map((mt) => {
        const wageInfo = this.wageTypeInfo.get(mt)
        return {
          key: mt,
          value: wageInfo?.name || 'unknown',
          checked: false,
          visible: true,
        }
      })
  }

  private unitTypeFormat(ut: BaseUnitTypeID) {
    if (this.isCustomUnitType(ut)) {
      return 'Custom'
    }
    return ut.charAt(0).toUpperCase() + ut.slice(1).toLowerCase()
  }

  public unitsToSelectValue(units: Unit[]): SelectSubItem[] {
    return units.map((unit) => {
      let symbolStr = `(${this.UNIT_PREFIXES[unit.prefix].symbol + (unit.baseUnit.symbol || this.BASE_UNIT_SYMBOLS[unit.baseUnit.unit])})`
      if (this.isCustomUnitType(unit.baseUnit.unitType)) {
        symbolStr = ''
      }
      const item: SelectSubItem = {
        key: `${unit.baseUnit.unit}:${unit.prefix}`,
        value: `${this.unitTypeFormat(unit.baseUnit.unitType)}: ${this.baseUnitPrefixName(unit.baseUnit.unit, unit.prefix)} ${symbolStr}`,
        checked: false,
        visible: true,
      }

      if (unit.baseUnit.unitType === 'HDD') {
        item.infoText = 'Monthly HDD data from an official source which can be linked to a metering point for that region.'
      }

      if (unit.baseUnit.unitType === 'CDD') {
        item.infoText = 'Monthly CDD data from an official source which can be linked to a metering point for that region.'
      }
      return item
    })
  }

  public getAllUnitTypes() {
    // There is a race condition and so we must wait for units and metrics to have been loaded
    if (!this.baseUnits?.length || !this.flatMeterMetrics?.length) {
      return
    }
    const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.toLowerCase().slice(1)
    let allTypes: SelectValue<string>[] = []

    const composedUnits = this.composedBaseUnits.map((bu) => {
      return {
        key: bu,
        value: capitalize(bu),
      }
    })

    // Note: This is arbitrary, might want to add these and other in later
    const usedUnits = this.flatMeterMetrics.map((m) => m.unit.baseUnit.unitType)
    allTypes = this.baseTypes
      .filter((bu) => usedUnits.includes(bu))
      .map((bu) => {
        let value = capitalize(bu)
        if (this.isCustomUnitType(bu)) {
          value = 'Custom: ' + bu.substring(7)
        }

        return {
          key: bu,
          value: value,
        }
      })

    composedUnits.forEach((cu) => {
      if (!allTypes.find((sv) => sv.key === cu.key)) {
        allTypes.push(cu)
      }
    })
    return allTypes
  }

  public getDeviceId(metricId: string) {
    return this.deviceMappings.find((mapping) => mapping.metricId === metricId)?.deviceId
  }

  public getQueryableTypes(endpointIds: string[]) {
    return endpointIds.map((id) => {
      return { id, type: this.getDataSourceNodeType(id) }
    })
  }

  public getWidgetBaseUnitType(widget: Widget): BaseUnitTypeID {
    if (!(widget.singleOutputUnit && (widget.unitType.dividend.length > 1 || widget.unitType.divisor.length))) {
      return 'kpi'
    } else {
      return widget.singleOutputUnit?.baseUnit?.unitType || widget.unitType.dividend[0].unitType
    }
  }

  public getWidgetBaseUnit(widget: Widget) {
    if (!widget.singleOutputUnit && (widget.unitType.dividend.length > 1 || widget.unitType.divisor.length)) {
      return undefined
    } else {
      return widget.singleOutputUnit?.baseUnit?.unit || this.baseUnitTypeDefaults.get(widget.unitType.dividend[0].unitType)?.defaultOutputUnit.baseUnit.unit
    }
  }

  public getFrequencySummaryText(config: FrequencySelection) {
    const frequencyLooking: Record<RecurringFrequencies, string> = {
      DAILY: 'day',
      WEEKLY: 'week',
      MONTHLY: 'month',
      YEARLY: 'year',
    }
    let summary = 'every'

    if (config.frequency === 'DAILY') {
      summary += ` ${frequencyLooking[config.frequency]}`
    }

    if (config.frequency === 'WEEKLY') {
      // Note: We should always have a value for 'every' if it's a week
      if (!config.config.every) {
        console.warn('Missing value for every')
        return 'error'
      }

      if (config.config.every > 1) {
        summary += ` ${config.config.every} ${frequencyLooking[config.frequency]}s on`
      } else {
        summary += ` ${frequencyLooking[config.frequency]} on`
      }

      if (!config.config.on?.length) {
        summary += '...'
      } else {
        config.config.on?.forEach((w, i) => {
          if (i > 0) {
            summary += ', '
          }
          summary += ` ${w}`
        })
      }
    }

    if (config.frequency === 'MONTHLY') {
      // Note: We should always have a value for 'every' if it's a week
      if (!config.config.every) {
        console.warn('Missing value for every')
        return 'error'
      }

      if (config.config.every > 1) {
        summary += ` ${config.config.every} ${frequencyLooking[config.frequency]}s on the `
      } else {
        summary += ` ${frequencyLooking[config.frequency]} on the `
      }

      if (!config.config.on?.length) {
        summary += '...'
      } else {
        if (typeof config.config.on[0] === 'number') {
          // Day number(s)
          config.config.on.forEach((d, i) => {
            if (i > 0) {
              summary += ', '
            }
            summary += format(new Date().setDate(d as number), 'do')
          })
        } else {
          // First day of the week etc
          summary += `${config.config.on[0] as string} ${this.dayNames[config.config.on[1] as WeekdayIDs]}`
        }
      }
    }

    if (config.frequency === 'YEARLY') {
      // Note: We should always have a value for 'every' if it's a week
      if (!config.config.every) {
        console.warn('Missing value for every')
        return 'error'
      }

      if (config.config.every > 1) {
        summary += ` ${config.config.every} ${frequencyLooking[config.frequency]}s on the `
      } else {
        summary += ` ${frequencyLooking[config.frequency]} on the `
      }

      if (!config.config.on?.length || config.config.on?.length < 2 || !(config.config.on[0] as []).length || !(config.config.on[1] as []).length) {
        summary += '...'
      } else {
        if (config.config.on.length > 2) {
          // first/second... day of week
          summary += `${config.config.on[1] as string} ${this.dayNames[config.config.on[2] as WeekdayIDs]}`
        } else {
          // numeric days of month
          ;(config.config.on[1] as number[]).forEach((n, i) => {
            if (i > 0) {
              summary += ', '
            }
            summary += format(new Date().setDate(n as number), 'do')
          })
        }

        // Months
        summary += ' of '
        ;(config.config.on[0] as string[]).forEach((m, i) => {
          if (i > 0) {
            summary += ', '
          }
          summary += `${m}`
        })
      }
    }

    summary += ` at the ${config.evaluationAt === 'END_OF_DAY' ? 'end' : 'start'} of the day`

    return summary
  }
}
