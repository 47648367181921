import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { Observable, Subject } from 'rxjs'
import { HttpBaseService } from './http-base.service'
import {
  EmmaAlertMessage,
  IServiceNotification,
  NotificatioNCapableServices,
  NotificationPriority,
  StreamedDeviceNotification,
  SystemNotification,
} from './proficloud.interfaces'
import { ProficloudService } from './proficloud.service'

export interface IMessageFullResponseDTO {
  id: string
  userId: string
  organizationId: string
  actionId: string
  type: 'INFO' | 'WARNING' | 'ALERT'
  data: string
  service: string
  acknowledged: boolean
  created: Date
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private messageEventSource: EventSourcePolyfill
  public allNotifications: Array<SystemNotification> = []
  public notDismissedNotifications: Array<SystemNotification> = []

  public showDeleteConfirmation$ = new Subject<SystemNotification>()
  public showNotifications$ = new Subject<boolean>()
  public dismissAllRequired$ = new Subject<boolean>()

  constructor(
    private proficloud: ProficloudService,
    public http: HttpClient,
    private httpBase: HttpBaseService
  ) {}

  private addDummyInternalNotifications() {
    const now = new Date()

    const autoGeneratedDummies: StreamedDeviceNotification[] = []

    const services: NotificatioNCapableServices[] = ['device-management', 'user-management']
    const priorities: NotificationPriority[] = ['info', 'warning', 'alert']

    const dummies: number = 10

    for (let i = 0; i < dummies; i++) {
      // generate id
      const stringArr = []
      for (let x = 0; x < 2; x++) {
        // tslint:disable-next-line:no-bitwise
        const part = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
        stringArr.push(part)
      }

      autoGeneratedDummies.push({
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget aliquam ultricies, nunc nisl aliquet nunc, eget aliquam nisl nisl nec nunc.',
        id: stringArr.join('-'),
        triggerTS: '', // TODO
        responsible: services[Math.floor(Math.random() * services.length)],
        priority: priorities[Math.floor(Math.random() * priorities.length)],
      })
    }

    autoGeneratedDummies.forEach((d) => {
      this.allNotifications.push({
        serviceNotification: d,
        received: new Date(),
        dismissed: false,
      })
    })
  }

  public setupAlertStreams() {
    this.proficloud.organisationSwitched$.subscribe(() => {
      this.allNotifications.length = 0
    })
  }

  /**
   * 1. Low priority alerts should be dismissed either after 1 minute or when they're viewed in the log
   * 2. The top 50 alerts based on priority/received/dismissed need to be selected
   */
  public maintainAlerts() {}

  private getPersistedMessages$(): Observable<any> {
    const url = `${this.httpBase.backendUrls.notificationServiceUrl}/api/message/${this.proficloud.keycloakData.userDetails?.data.userId}`

    // *Turn on in order to activate dummy notifications
    // this.addDummyInternalNotifications()

    return this.http.get(url)
  }

  public getAlertsSummary() {
    const lowCount = this.allNotifications.filter((a) => !a.dismissed).filter((a) => (a.serviceNotification as IServiceNotification).priority === 'info').length
    const mediumCount = this.allNotifications
      .filter((a) => !a.dismissed)
      .filter((a) => (a.serviceNotification as IServiceNotification).priority === 'info').length
    const highCount = this.allNotifications
      .filter((a) => !a.dismissed)
      .filter((a) => (a.serviceNotification as IServiceNotification).priority === 'warning').length

    if (highCount > 0) {
      return {
        high: highCount,
      }
    } else if (mediumCount > 0) {
      return {
        medium: mediumCount,
      }
    } else if (lowCount > 0) {
      return {
        low: lowCount,
      }
    } else {
      return {}
    }
  }

  public dismissAllNotifications() {
    this.allNotifications.forEach((systemNotification) => {
      // Note: The dismissed property on the service notification is not persisted
      systemNotification.dismissed = true

      // Persist acknowledge for other than EMMA notification
      if (systemNotification.persisted && systemNotification.serviceNotification.responsible !== 'emma') {
        let messageId = (systemNotification.serviceNotification as IServiceNotification).id
        let responsible = (systemNotification.serviceNotification as IServiceNotification).responsible

        this.acknowledgePersistedNotification(messageId, responsible).subscribe({
          next: (v) => {
            this.setNotDismissed()
          },
          error: (v) => {
            console.log('error', v)
          },
        })
      } else if (systemNotification.dismissed) {
        this.notDismissedNotifications = this.notDismissedNotifications.filter(
          (item) => item.serviceNotification.id === systemNotification.serviceNotification.id
        )
      }
    })

    this.dismissAllRequired$.next(true)
  }

  public setNotDismissed() {
    this.removeDuplicateNotifications()
    this.notDismissedNotifications = this.allNotifications.filter((n) => {
      return (
        (!this.isEmmaAlertMessage(n.serviceNotification) && !n.dismissed) ||
        (this.isEmmaAlertMessage(n.serviceNotification) && !n.serviceNotification.acknowledged)
      )
    })
  }

  public removeDuplicateNotifications() {
    // removing duplicate ids
    let count: string[] = []
    let tmp: Array<SystemNotification> = []

    this.allNotifications.forEach((n) => {
      if (!count.includes(n.serviceNotification.id)) {
        count.push(n.serviceNotification.id)
        tmp.push(n)
      }
    })
    this.allNotifications = tmp
  }

  public addInternalNotification(description: string, priority: NotificationPriority, received: Date, responsible: NotificatioNCapableServices) {
    // Feeling Cute Might Delete Later
    const rdmID = '_' + Math.random().toString(36).substr(2, 9)
    const newNTFC: SystemNotification = {
      serviceNotification: {
        id: rdmID,
        responsible: responsible,
        priority,
        description: description,
        triggerTS: '', // TODO
      },
      dismissed: false,
      received,
    }

    this.allNotifications.push(newNTFC)
    this.setNotDismissed()
  }

  public dismissOrShowConfirmation(notification: SystemNotification) {
    if ((notification.serviceNotification as IServiceNotification).priority === 'alert') {
      this.showDeleteConfirmation$.next(notification)
    } else {
      notification.dismissed = true
      // tell the message service that the message is dismissed
      if (notification.persisted) {
        let messageId = (notification.serviceNotification as IServiceNotification).id
        let responsible = (notification.serviceNotification as IServiceNotification).responsible

        this.acknowledgePersistedNotification(messageId, responsible).subscribe((res) => {
          this.setNotDismissed()
        })
      }
    }
  }

  public dismissNotification(notification: SystemNotification) {
    this.allNotifications = this.allNotifications.filter((n) => n.serviceNotification.id !== notification.serviceNotification.id)
    if (notification.persisted || notification.serviceNotification.responsible === 'emma') {
      let messageId = (notification.serviceNotification as IServiceNotification).id
      let responsible = (notification.serviceNotification as IServiceNotification).responsible
      this.acknowledgePersistedNotification(messageId, responsible).subscribe((res) => {
        this.setNotDismissed()
      })
    }
  }

  public removeEmmaTopBarNotification(id: string) {
    this.allNotifications.forEach((n) => {
      if (n.serviceNotification.id === id || (n.serviceNotification as EmmaAlertMessage).alertRuleID === id) {
        this.dismissNotification(n)
      }
    })
  }

  setupMessagingBackend() {
    // get persistent messages from backend
    this.getPersistedMessages$().subscribe({
      next: (messages: IMessageFullResponseDTO[]) => {
        messages.forEach((message) => {
          this.allNotifications.push(NotificationService.mapMessageToSystemNotification(message))
        })
      },
      error: (err: HttpErrorResponse) => {
        console.log(err)
      },
    })

    // open sse connection to message service
    this.openNotificationServiceConnection()

    // listen for organization switch event
    this.proficloud.organisationSwitched$.subscribe(() => {
      // closing of existing connection happens in the following method
      this.openNotificationServiceConnection()

      // remove existing persisted notifications
      this.allNotifications = this.allNotifications.filter((ntfc) => !ntfc.persisted)

      this.getPersistedMessages$().subscribe({
        next: (messages: IMessageFullResponseDTO[]) => {
          messages.forEach((message) => {
            this.allNotifications.push(NotificationService.mapMessageToSystemNotification(message))
          })
          this.setNotDismissed()
        },
        error: (err) => {
          console.warn('It was not possible to get the messages of the current user from notification service. ', err)
        },
      })
    })
  }

  /* Maps a message coming from the notification service to an internal SystemNotification */
  private static mapMessageToSystemNotification(message: IMessageFullResponseDTO): SystemNotification {
    const priority = message.type.toLowerCase() as NotificationPriority
    let service

    /*
    export enum Service {
  UNKNOWN,
  DEVICE_LIFECYCLE,
  USER_MANAGEMENT,
  EMMA,
  MACHINE_MANAGER,
  BILLING,
  E_MOBILITY,
  TSD,
  IMPULSE_ANALYTICS,
  DMS,
  PKI,
  FIRMWARE_UPDATE,
}
     */

    // todo: implement the service list as described above
    switch (message.service) {
      case 'DEVICE_LIFECYCLE':
        service = 'device-management'
        break
      case 'USER_MANAGEMENT':
        service = 'user-management'
        break
      case 'EMMA':
        service = 'emma'
        break
      default:
        service = 'unknown'
    }

    return {
      serviceNotification: {
        priority: priority,
        responsible: service as NotificatioNCapableServices,
        id: message.id,
        description: message.data,
        triggerTS: '',
      },
      dismissed: message.acknowledged,
      received: message.created,
      persisted: true,
    }
  }

  private openNotificationServiceConnection() {
    return
    /*this.messageEventSource?.close()
    //return // Commented out for the time being since the backend isn't active and we don't want unnecessary extra connections

    const url = `${this.httpBase.backendUrls.notificationServiceUrl}/sse`
    this.messageEventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${(this.proficloud.keycloakData.session as SessionData).access_token}`,
      },
    })

    // Listen for pings
    this.messageEventSource.addEventListener(`heartbeat`, (evt: MessageEvent) => {
      console.debug('sse ping from notification service')
    })

    // Listens to user specific messages
    this.messageEventSource.addEventListener(
      `notifications-${this.proficloud.keycloakData.userDetails.data.userId}`,
      (evt: MessageEvent) => {
        const message = JSON.parse(evt.data) as unknown as IMessageFullResponseDTO
        if (message) {
          if (message.actionId) {
            // Call the correct engine and trigger an action
            switch (message.service) {
              case 'USER_MANAGEMENT':
                this.userMgmtNtfcEngine.proceedAction(message.actionId)
            }
          }
          this.allNotifications.push(NotificationService.mapMessageToSystemNotification(message))
        }
      }
    )

    // Listens to organization wide messages.
    if (this.proficloud.currentOrganisation) {
      this.messageEventSource.addEventListener(
        `notifications-${this.proficloud.currentOrganisation.organizationId}`,
        (evt: MessageEvent) => {
          const message = JSON.parse(evt.data) as unknown as IMessageFullResponseDTO

          if (message) {
            this.allNotifications.push(NotificationService.mapMessageToSystemNotification(message))
          }
        }
      )
    }*/
  }

  public acknowledgePersistedNotification(messageId: string, responsible: string) {
    if (responsible === 'emma') {
      const url = `${this.httpBase.backendUrls.emmaNotificationUrl}/api/v1/alert-messages/${messageId}/acknowledged`
      return this.http.patch(url, {})
    } else {
      const url = `${this.httpBase.backendUrls.notificationServiceUrl}/api/message/acknowledge/${messageId}`
      return this.http.post(url, {})
    }
  }

  public isEmmaAlertMessage(message: IServiceNotification): message is EmmaAlertMessage {
    return !!message && 'acknowledged' in message
  }
}
